<template>
  <div>
    <el-form ref="formRef" :model="form" class="form-wrapper" :disabled="loading">
      <div v-if="!hasCountryCode" class="form-content">
        <span>{{ $t('createBusinessModal.form.countryCode') }}</span>
        <el-form-item prop="countryCode" :rules="validationRules?.countryCode">
          <el-select
            v-model="form.countryCode"
            popper-class="select-country"
            trigger="click"
            @change="onCountryCodeSelect"
          >
            <el-option
              v-for="(countryCodeOption, index) in countryCodeOptions"
              :key="`countryCode-${index}`"
              :label="countryCodeOption.label"
              :value="countryCodeOption.value"
            />
          </el-select>
        </el-form-item>
      </div>
      <div class="form-content low-form">
        <span> {{ $t('createBusinessModal.form.businessType') }}</span>
        <div class="checkbox-wrapper d-flex">
          <div>
            <el-checkbox v-model="form.capabilities.restaurant" name="type" />
            <span @click="form.capabilities.restaurant = !form.capabilities.restaurant">
              {{ $t('createBusinessModal.form.restaurant') }}</span
            >
          </div>
          <div>
            <el-checkbox v-model="form.capabilities.supplier" name="type" />
            <span @click="form.capabilities.supplier = !form.capabilities.supplier">
              {{ $t('createBusinessModal.form.supplier') }}</span
            >
          </div>
          <div class="text-nowrap">
            <el-checkbox v-model="form.capabilities.bookkeeping" name="type" />
            <span @click="form.capabilities.bookkeeping = !form.capabilities.bookkeeping">
              {{ $t('createBusinessModal.form.bookKeeper') }}</span
            >
          </div>
        </div>
      </div>
      <div v-if="!validCheckbox" class="form-content">
        <span />
        <div class="checkbox-error d-flex gap-1">
          <NoticeIcon />
          <span>{{ $t('createBusinessModal.form.checkboxError') }}</span>
        </div>
      </div>
      <div class="form-content mt-4">
        <span> {{ $t('createBusinessModal.form.businessName') }}</span>

        <el-form-item prop="name" :rules="validationRules?.name">
          <el-input v-model="form.name" />
        </el-form-item>
      </div>
      <div class="form-content">
        <span> {{ $t('createBusinessModal.form.officiaBusinessName') }}</span>

        <el-form-item prop="legalName" :rules="validationRules?.legalName">
          <el-input v-model="form.legalName" />
        </el-form-item>
      </div>
      <div class="form-content">
        <span> {{ $t('createBusinessModal.form.businessNumber') }}</span>

        <el-form-item prop="number" :rules="validationRules?.number">
          <el-input v-model="form.number" @input="form.number = form.number.replace(/\D/g, '')" />
        </el-form-item>
      </div>
      <div class="form-content">
        <span> {{ $t('createBusinessModal.form.email') }}</span>

        <el-form-item prop="email">
          <el-input v-model="form.email" class="email-field" />
        </el-form-item>
      </div>
      <div class="form-content">
        <span> {{ $t('createBusinessModal.form.phoneNumber') }}</span>

        <el-form-item prop="phone" :rules="validationRules?.phone">
          <el-input v-model="form.phone" dir="ltr" />
        </el-form-item>
      </div>
      <div class="form-content">
        <span> {{ $t('createBusinessModal.form.tenantEmail') }}</span>

        <el-form-item prop="tenantEmail" :rules="validationRules?.tenantEmail">
          <el-input v-model="form.tenantEmail" class="email-field" :disabled="!form.capabilities.restaurant">
            <template v-if="$direction === 'rtl'" #prepend class="email-prepend"><span>clarityo.com@</span></template>
            <template v-if="$direction === 'ltr'" #append class="email-prepend"><span>@clarityo.com</span></template>
          </el-input>
        </el-form-item>
      </div>
    </el-form>
    <div class="action-buttons d-flex flex-row-reverse">
      <Button v-if="!loading" size="large" @click="validateForm(formRef)">
        {{ $t('createBusinessModal.form.actionButtons.save') }}
      </Button>
      <Button v-if="loading" type="secondary" size="large" disabled>
        <div v-loading="true" class="inner-button-loading" />
        {{ $t('createBusinessModal.form.actionButtons.save') }}
      </Button>
      <Button size="large" type="secondary" class="mx-2" :disabled="loading" @click="goBack(formRef)">
        {{ $t('createBusinessModal.form.actionButtons.back') }}
      </Button>
    </div>
  </div>
</template>
<script>
import { ref, computed, getCurrentInstance } from 'vue';

import { Button } from '@/modules/core';
import { NoticeIcon } from '@/assets/icons';
import { createBusinessFormDefaultValues } from '../../constants';

export default {
  components: {
    Button,
    NoticeIcon,
  },
  props: {
    form: {
      type: Object,
      default: () => createBusinessFormDefaultValues,
    },
    loading: { type: Boolean, default: false },
    hasCountryCode: { type: Boolean, default: true },
  },
  emits: ['on-form-submit', 'on-back-click'],
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;

    const formRef = ref();
    const validCheckbox = ref(true);
    const countryCodeOptions = [
      { label: root.$t('createBusinessModal.form.countryCodeOptions.il'), value: 'IL' },
      { label: root.$t('createBusinessModal.form.countryCodeOptions.us'), value: 'US' },
    ];

    const validationRules = computed(() => ({
      name: [{ required: true, type: 'string', trigger: 'blur' }],
      legalName: [{ required: true, type: 'string', trigger: 'blur' }],
      number: [{ required: props?.form?.countryCode === 'IL', type: 'string', trigger: 'blur' }],
      phone: [{ required: true, type: 'string', trigger: 'blur' }],
      tenantEmail: [
        {
          required: !!props.form?.capabilities?.restaurant,
          type: 'string',
          trigger: 'blur',
        },
        {
          trigger: 'blur',
          validator: (rule, value, cb) =>
            value.includes(' ') ? cb(new Error('Email field should be one word')) : cb(),
        },
      ],
      countryCode: [{ required: !props?.hasCountryCode, type: 'string', trigger: 'blur' }],
    }));

    const validateForm = async (formReference) => {
      await formReference.validate((isFormValid) => {
        validCheckbox.value = !!(
          props.form.capabilities.restaurant ||
          props.form.capabilities.supplier ||
          props.form.capabilities.bookkeeping
        );

        if (isFormValid && validCheckbox.value) {
          emit('on-form-submit');
        }
      });
    };

    const goBack = (formReference) => {
      validCheckbox.value = true;
      formReference.clearValidate();
      emit('on-back-click');
    };

    const onCountryCodeSelect = (countryCodeSelected) => {
      const relevantCountryCodeOption = countryCodeOptions.find(({ value }) => value === countryCodeSelected);
      props.form.address = `${props.form.defaultAddress}, ${relevantCountryCodeOption.label}`;
    };

    return {
      formRef,
      validCheckbox,
      validationRules,
      countryCodeOptions,
      goBack,
      validateForm,
      onCountryCodeSelect,
    };
  },
};
</script>
<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.form-wrapper {
  width: 32rem;
  height: 32.1rem;

  .form-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    height: 2rem;

    span {
      font-size: 0.875rem;
    }

    .el-input,
    button {
      width: 23rem;
    }

    .el-select {
      width: 23rem;
    }

    .checkbox-wrapper {
      width: 23rem;

      span {
        margin-inline-start: 0.5rem;
        margin-inline-end: 1.5rem;
        &:hover {
          cursor: pointer;
        }
      }
    }

    .notice-icon {
      color: $typography-secondary;
      width: 1.063rem;
      height: 1.063rem;
      margin-top: 0.125rem;
    }
    .logo-confirmation {
      color: $typography-secondary;
      font-size: 0.75rem;
    }

    .checkbox-error {
      color: $checkbox-error;

      svg {
        width: 1.063rem;
        height: 1.063rem;
        margin-top: 0.22rem;
      }
    }
  }
  .form-content.low-form {
    margin-bottom: 0;
  }
}

::v-deep {
  .el-select {
    i.el-input__icon {
      display: inline-block;
      line-height: 20px;
    }

    i.el-input__validateIcon {
      display: none;
    }
  }
  .el-input-group__prepend {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-right: 1px solid $light-border-gray;

    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: $typography-secondary;
    }
  }

  .el-input-group__append {
    span {
      color: $typography-secondary;
    }
  }

  .el-input-group__prepend + input {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    border-left: 1px solid $light-border-gray;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;

    &:focus {
      border-left: 1px solid $primary;
    }
  }

  .el-checkbox__inner {
    z-index: 0 !important;
  }

  .el-input__inner {
    height: 2rem !important;
  }

  .el-form-item {
    margin: 0;
  }

  .el-form-item__error {
    display: none !important;
  }
}
</style>
<style lang="scss">
.select-country {
  div.el-select-dropdown__wrap {
    overflow: auto;
  }
}
</style>
