<template>
  <p class="title-number">
    <span class="title-number-content">{{ number }}</span>
  </p>
</template>
<script>
export default {
  name: 'CollapseItemHeaderNumber',
  props: {
    number: { type: Number, required: true },
  },
};
</script>
<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.title-number {
  font-size: 0.875rem;
  border-radius: 1.25rem;
  border: 1px solid $gray-outline;
  width: 1.5rem;
  height: 1.5rem;
  margin-inline-end: 0.75rem !important;
  text-align: center;

  .title-number-content {
    position: relative;
    bottom: 0.75rem;
  }
}
</style>
