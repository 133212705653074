<template>
  <div>
    <div class="selected-item-title mt-5 d-flex">
      <div class="p-1">
        <ImagePlaceholderIcon />
      </div>
      <div class="mx-4">
        <h3 v-if="form?.name">{{ form.name }}</h3>
        <span v-if="form?.legalName">{{ form.legalName }}</span> <br />
        <span v-if="form?.number">ח.פ {{ form.number }}</span>
      </div>
    </div>

    <div v-if="form?.summary" class="selected-item-description mt-5">
      <p>
        {{ form.summary }}
      </p>
    </div>

    <div class="divider my-4" />

    <div class="selected-item-details d-flex flex-column">
      <div v-if="form?.capabilities?.restaurant" class="mb-4 d-flex gap-4">
        <StoreIcon class="icon" /> <span>{{ $t('createBusinessModal.form.restaurant') }}</span>
      </div>
      <div v-if="form?.capabilities?.supplier" class="mb-4 d-flex gap-4">
        <SupplierNewIcon class="icon" /> <span>{{ $t('createBusinessModal.form.supplier') }}</span>
      </div>
      <div v-if="form?.capabilities?.bookkeeping" class="mb-4 d-flex gap-4">
        <BookkeepingIcon class="icon" /> <span>{{ $t('createBusinessModal.form.bookKeeper') }}</span>
      </div>
      <div v-if="form?.address && form?.countryCode !== ''" class="mb-4 d-flex gap-4">
        <MapPinIcon class="icon" /> <span>{{ form.address }}</span>
      </div>
      <div v-if="form?.websiteUri" class="mb-4 d-flex gap-4">
        <LinkIconNew class="icon" /><span dir="ltr"
          ><a :href="form.websiteUri" target="_blank">{{ form.websiteUri }}</a></span
        >
      </div>
      <div v-if="form?.phone" class="mb-4 d-flex gap-4">
        <PhoneIcon class="icon" />
        <span dir="ltr">{{ form.phone }}</span>
      </div>
      <div v-if="form?.email" class="mb-4 d-flex gap-4">
        <EmailIcon class="icon" /> <span>{{ form?.email }}</span>
      </div>
    </div>

    <div v-if="form?.googleMapsUri" class="selected-item-google-maps-href">
      <a :href="form.googleMapsUri" target="_blank">
        Google Maps
        <ExternalLinkIcon />
      </a>
    </div>
  </div>
</template>
<script>
import {
  ImagePlaceholderIcon,
  MapPinIcon,
  LinkIconNew,
  PhoneIcon,
  ExternalLinkIcon,
  EmailIcon,
  StoreIcon,
  SupplierNewIcon,
  BookkeepingIcon,
} from '@/assets/icons';

import { createBusinessFormDefaultValues } from '../../constants';

export default {
  name: 'CreateBusinessAdditionalInfo',
  components: {
    ImagePlaceholderIcon,
    MapPinIcon,
    LinkIconNew,
    PhoneIcon,
    ExternalLinkIcon,
    EmailIcon,
    StoreIcon,
    SupplierNewIcon,
    BookkeepingIcon,
  },
  props: {
    form: {
      type: Object,
      default: () => createBusinessFormDefaultValues,
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.selected-item-title {
  div:nth-of-type(1) {
    height: 40px;
    border-radius: 0.25rem;
    background: $gray;

    svg {
      width: 2rem;
      height: 2rem;
    }
  }

  div:nth-of-type(2) {
    color: $typography-secondary;
    font-size: 0.75rem;
    height: 3.25rem;

    h3 {
      font-size: 1rem;
      color: $typography-primary;
    }
  }
}

.divider {
  height: 1px;
  background: $gray-outline;
}

.selected-item-details {
  .icon {
    width: 20px !important;
    height: 20px !important;
    color: $typography-secondary;
    flex-shrink: 0;
  }

  span {
    a {
      color: inherit;
    }
  }
}

.selected-item-google-maps-href {
  position: absolute;
  bottom: 0;
  padding: 2rem;
  [dir='rtl'] & {
    left: 0;
  }
  [dir='ltr'] & {
    right: 0;
  }
  a {
    color: $typography-secondary;
    font-size: 0.875rem;
    text-decoration-line: underline;
    svg {
      margin-bottom: 2px;
    }
  }
}
</style>
